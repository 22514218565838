import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { SlideDown } from "react-slidedown";

import Expand from "../Expand/Expand";

import { MOBILE_VIEW } from "../../config/config";

import classes from "./Question.module.css";

import { trackDigitalData } from "../../helpers/analytics";

/**
 * RFC for RMS questions
 * @param {*} props
 * @returns <JSX />
 */
const Question = (props) => {
  const [canRender, setCanRender] = useState(props.open);
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= MOBILE_VIEW
  );

  const radioHandler = (e) => {
    const answer = e.target.value;
    const config = [...props.config];
    config[props.index] = { ...config[props.index], answer };
    props.setConfig(config);
    props.setIsFormTouched(true);

    switch (props.id) {
      case 1:
        trackDigitalData('interaction', 'ats06292', false, { answer });
        break;
      case 2:
        trackDigitalData('interaction', 'ats06293', false, { answer });
        break;
      case 3:
        trackDigitalData('interaction', 'ats06297', false, { answer });
        break;
      case 4:
        trackDigitalData('interaction', 'ats06296', false, { answer });
        break;
      case 5:
        trackDigitalData('interaction', 'ats06295', false, { answer });
        break;
      case 6:
        trackDigitalData('interaction', 'ats06294', false, { answer });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        let isMobile = window.innerWidth <= MOBILE_VIEW;
        setIsMobileView(isMobile);
      }, 150);
    };
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [props]);

  const toggleContent = (
    <Row>
      <Col
        md={10}
        sm={12}
        className={`${classes.questionBlock} q-b-${props.id}`}
      >
        <p>{props.question}</p>
      </Col>
      <Col md={2} sm={12} className={`${classes.radioBlock} r-b-${props.id}`}>
        <Form>
          <div className={`${classes.radioButtonBlock} mb-1`}>
            <label className="mb-0" htmlFor={"inlineRadio-1-" + props.id}>
              Yes
            </label>
            <input
              className={`${classes.radioButton}`}
              type="radio"
              name={"radio-" + props.id}
              onChange={radioHandler}
              value="yes"
              id={"inlineRadio-1-" + props.id}
              checked={props.answer === "yes"}
            //onClick={() => trackDigitalData('interaction', props.trackId)}
            />
          </div>
          <div className={`${classes.radioButtonBlock}`}>
            <label className="mb-0" htmlFor={"inlineRadio-2-" + props.id}>
              No
            </label>
            <input
              className={`${classes.radioButton}`}
              type="radio"
              name={"radio-" + props.id}
              onChange={radioHandler}
              value="no"
              id={"inlineRadio-2-" + props.id}
              checked={props.answer === "no"}
            //onClick={() => trackDigitalData('interaction', props.trackId)}
            />
          </div>
        </Form>
      </Col>
    </Row>
  );

  const dymaicClass =
    props.isFormSubmitted && props.answer === null
      ? classes.validationError
      : "";

  return (
    <>
      <Row className={`${classes.questionContainer} mb-3 mt-3 ${dymaicClass}`}>
        <Col md={1} sm={1} className={classes.numberBlock}>
          <span className={`${classes.number}`}>{props.id}.</span>
          <span className={`${classes.questionNumber}`}>
            Question {props.id}
          </span>
          <Expand question collapse={canRender} setCollapse={setCanRender} />
        </Col>

        {isMobileView ? (
          <Col md={11} sm={11} xs={11} className="question-mobile-view">
            <SlideDown transitionOnAppear={false}>
              {(canRender || props.expandAll) && toggleContent}
            </SlideDown>
          </Col>
        ) : (
          <Col md={11} sm={11} xs={11} className={`${classes.desktopView}`}>
            {toggleContent}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Question;
