const ADOBE_LAUNCH = {
    production: 'https://assets.adobedtm.com/acb96670c057/31fbd82ca9ee/launch-46b4037bbe69.min.js',
    development: 'https://assets.adobedtm.com/acb96670c057/31fbd82ca9ee/launch-e1209d05f350-development.min.js'
};

const loadAdobeLaunch = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = window.location.href === 'https://www.rapidmodscreener.com/'
        ? ADOBE_LAUNCH.production
        : ADOBE_LAUNCH.development;
    document.head.appendChild(script);
    console.log(script.src);
};

loadAdobeLaunch();


/* global _satellite */

let globalValues = {};
let digitalData;

export const trackDigitalData = (action, key, overRide = false, formData = {}, stepname = {}) => {
    console.log(key);
    digitalData = {
        page: analyticsLookUp[key]
    };

    if (overRide) {
        digitalData.page.pageInfo = globalValues.pageInfo;
        digitalData.page.category = globalValues.category;
        digitalData.page.attributes.type = globalValues.attributes.type;
        digitalData.page.attributes.globalISI = globalValues.attributes.globalISI;
        digitalData.page.attributes.globalISIPromoMatDocID = globalValues.attributes.globalISIPromoMatDocID;
        digitalData.page.journey = globalValues.journey;
        digitalData.page.link.internalLinkTracking = globalValues.link.internalLinkTracking;
    }

    delete digitalData.page.metadata;
    delete digitalData.page.projectDetails;
    delete digitalData.page.tracking;
    delete digitalData.page.pixels;

    let mva = {
        "name": digitalData.page.mva.name,
        "tier": digitalData.page.mva.tier,
        "type": digitalData.page.mva.type,
        "category": digitalData.page.mva.category
    };

    if (Object.keys(formData).length > 0) {
        digitalData.page.form.fieldValue = formData.answer;
    }

    if (Object.keys(stepname).length > 0) {
        digitalData.page.form.stepName = stepname.stepName;
    }

    digitalData.page.mva = mva;

    window.digitalData = digitalData;
    console.log(JSON.stringify(digitalData));
    console.log(action, digitalData);

    if (action === 'pageLoad') {
        globalValues = analyticsLookUp[key];
        trackWithSatellite('trackState');
    } else {
        trackWithSatellite('trackAction');
    }
};

function trackWithSatellite(trackType) {
    if (typeof _satellite !== "undefined") {
        console.log(`Tracking with type: ${trackType}`);
        _satellite.track(trackType);
    } else {
        console.error('_satellite is undefined, retrying...');
        setTimeout(() => trackWithSatellite(trackType), 100); // Retry after 100 milliseconds
    }
}

const analyticsLookUp = {
    "ats06044": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06044",
            "atsRecordKey": "rapidmodscreener_ats06044",
            "created": "11/19/24 3:07 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06089": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06089",
            "atsRecordKey": "rapidmodscreener_ats06089",
            "created": "11/19/24 3:58 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06090": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "patient case quizzes",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes",
            "path": "/patient-case-quizzes",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|patient case quizzes"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06090",
            "atsRecordKey": "rapidmodscreener_ats06090",
            "created": "11/19/24 3:58 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06245": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06245",
            "atsRecordKey": "rapidmodscreener_ats06245",
            "created": "11/20/24 4:19 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06091": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "multimedia",
            "prefix": "rapidmodscreener",
            "url": "/multimedia",
            "path": "/multimedia",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|multimedia"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06091",
            "atsRecordKey": "rapidmodscreener_ats06091",
            "created": "11/19/24 3:58 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06268": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06268",
            "atsRecordKey": "rapidmodscreener_ats06268",
            "created": "11/20/24 4:31 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06094": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "bipolar disorder",
            "prefix": "rapidmodscreener",
            "url": "/bipolar-disorder",
            "path": "/bipolar-disorder",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|bipolar disorder"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06094",
            "atsRecordKey": "rapidmodscreener_ats06094",
            "created": "11/19/24 4:01 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06093": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "quick guides for providers",
            "prefix": "rapidmodscreener",
            "url": "/quick-guides-for-providers",
            "path": "/quick-guides-for-providers",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|quick guides for providers"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06093",
            "atsRecordKey": "rapidmodscreener_ats06093",
            "created": "11/19/24 4:01 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06092": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinical article summaries",
            "prefix": "rapidmodscreener",
            "url": "/clinical-article-summaries",
            "path": "/clinical-article-summaries",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinical article summaries"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06092",
            "atsRecordKey": "rapidmodscreener_ats06092",
            "created": "11/19/24 4:01 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06284": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "characteristics of patients with early bipolar disorder",
            "prefix": "rapidmodscreener",
            "url": "/clinical-article-summaries/characteristics-of-patients-with-early-bipolar-disorder",
            "path": "/clinical-article-summaries/characteristics-of-patients-with-early-bipolar-disorder",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|characteristics of patients with early bipolar disorder"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06284",
            "atsRecordKey": "rapidmodscreener_ats06284",
            "created": "11/20/24 5:35 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06317": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "unrecognized bipolar disorder in patients with depression managed in primary care a systematic review and meta analysis",
            "prefix": "rapidmodscreener",
            "url": "/clinical-article-summaries/unrecognized-bipolar-disorder-in-patients-with-depression-managed-in-primary-care-a-systematic-review-and-meta-analysis",
            "path": "/clinical-article-summaries/unrecognized-bipolar-disorder-in-patients-with-depression-managed-in-primary-care-a-systematic-review-and-meta-analysis",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|unrecognized bipolar disorder in patients with depression managed in primary care a systematic review and meta analysis"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06317",
            "atsRecordKey": "rapidmodscreener_ats06317",
            "created": "11/20/24 7:21 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06307": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinician guide",
            "prefix": "rapidmodscreener",
            "url": "/clinician-guide",
            "path": "/clinician-guide",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinician guide"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06307",
            "atsRecordKey": "rapidmodscreener_ats06307",
            "created": "11/20/24 6:23 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06306": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "development",
            "prefix": "rapidmodscreener",
            "url": "/development",
            "path": "/development",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|development"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06306",
            "atsRecordKey": "rapidmodscreener_ats06306",
            "created": "11/20/24 6:23 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06108": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06108",
            "atsRecordKey": "rapidmodscreener_ats06108",
            "created": "11/19/24 4:33 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06095": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Page Level",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "page not found",
            "prefix": "rapidmodscreener",
            "url": "/page-not-found",
            "path": "/page-not-found",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|page not found"
        },
        "category": {
            "primaryCategory": "error page",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Error Page",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "",
            "displayTitle": "",
            "type": "",
            "url": "",
            "internalLinkTracking": ""
        },
        "component": {
            "name": "",
            "title": "",
            "position": "",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "",
            "howToCapture": "",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06095",
            "atsRecordKey": "rapidmodscreener_ats06095",
            "created": "11/19/24 4:21 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:01 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06045": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Yes, i am US healthcare professional",
            "displayTitle": "Yes, i am US healthcare professional",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|yes, i am us healthcare professional"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06045",
            "atsRecordKey": "[dynamic]_ats06045",
            "created": "11/19/24 3:07 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06046": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "No, I am not US healthcare professional",
            "displayTitle": "No, I am not US healthcare professional",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|no, i am not us healthcare professional"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06046",
            "atsRecordKey": "[dynamic]_ats06046",
            "created": "11/19/24 3:07 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06049": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Physician (Psychiatric)",
            "displayTitle": "Physician (Psychiatric)",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|physician (psychiatric)"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06049",
            "atsRecordKey": "[dynamic]_ats06049",
            "created": "11/19/24 3:07 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06053": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Nurse Practitioner (Psychiatric)",
            "displayTitle": "Nurse Practitioner (Psychiatric)",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|nurse practitioner (psychiatric)"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06053",
            "atsRecordKey": "[dynamic]_ats06053",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06054": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Physician Assistant (Psychiatric)",
            "displayTitle": "Physician Assistant (Psychiatric)",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|physician assistant (psychiatric)"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06054",
            "atsRecordKey": "[dynamic]_ats06054",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06056": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Physician (Family)",
            "displayTitle": "Physician (Family)",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|physician (family)"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06056",
            "atsRecordKey": "[dynamic]_ats06056",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06058": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Nurse Practitioner (Family)",
            "displayTitle": "Nurse Practitioner (Family)",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|nurse practitioner (family)"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06058",
            "atsRecordKey": "[dynamic]_ats06058",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06051": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Physician Assistant (Family",
            "displayTitle": "Physician Assistant (Family",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|physician assistant (family"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06051",
            "atsRecordKey": "[dynamic]_ats06051",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06052": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Pharmacist",
            "displayTitle": "Pharmacist",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|pharmacist"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06052",
            "atsRecordKey": "[dynamic]_ats06052",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06055": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Physician (Other)",
            "displayTitle": "Physician (Other)",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|physician (other)"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06055",
            "atsRecordKey": "[dynamic]_ats06055",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06050": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Nurse Practitioner (Other)",
            "displayTitle": "Nurse Practitioner (Other)",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|nurse practitioner (other)"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06050",
            "atsRecordKey": "[dynamic]_ats06050",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06057": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "if yes,"
        },
        "link": {
            "name": "Physician Assistant (Other)",
            "displayTitle": "Physician Assistant (Other)",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|physician assistant (other)"
        },
        "component": {
            "name": "cta",
            "title": "Welcome To Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06057",
            "atsRecordKey": "[dynamic]_ats06057",
            "created": "11/19/24 3:14 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06047": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "If no,"
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06047",
            "atsRecordKey": "[dynamic]_ats06047",
            "created": "11/19/24 3:07 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06048": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "If no,"
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06048",
            "atsRecordKey": "[dynamic]_ats06048",
            "created": "11/19/24 3:07 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 12:52 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06060": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "RMS logo",
            "displayTitle": "RMS logo",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|rms logo"
        },
        "component": {
            "name": "cta",
            "title": "RMS logo",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06060",
            "atsRecordKey": "[dynamic]_ats06060",
            "created": "11/19/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06061": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "about rms",
            "displayTitle": "about rms",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|about rms"
        },
        "component": {
            "name": "cta",
            "title": "about rms",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06061",
            "atsRecordKey": "[dynamic]_ats06061",
            "created": "11/19/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06304": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "access the tool",
            "displayTitle": "access the tool",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]|||access the tool"
        },
        "component": {
            "name": "cta",
            "title": "about rms",
            "position": "header",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06304",
            "atsRecordKey": "[dynamic]_ats06304",
            "created": "11/20/24 6:22 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06303": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "clinician guide",
            "displayTitle": "clinician guide",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]|||clinician guide"
        },
        "component": {
            "name": "cta",
            "title": "about rms",
            "position": "header",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06303",
            "atsRecordKey": "[dynamic]_ats06303",
            "created": "11/20/24 6:22 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06305": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "development and validation",
            "displayTitle": "development and validation",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]|||development and validation"
        },
        "component": {
            "name": "cta",
            "title": "about rms",
            "position": "header",
            "type": "",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06305",
            "atsRecordKey": "[dynamic]_ats06305",
            "created": "11/20/24 6:22 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06062": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Interactive Case Studies",
            "displayTitle": "Interactive Case Studies",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|interactive case studies"
        },
        "component": {
            "name": "cta",
            "title": "clinical experience",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06062",
            "atsRecordKey": "[dynamic]_ats06062",
            "created": "11/19/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06064": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "patient case quizzes",
            "displayTitle": "patient case quizzes",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|patient case quizzes"
        },
        "component": {
            "name": "cta",
            "title": "clinical experience",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06064",
            "atsRecordKey": "[dynamic]_ats06064",
            "created": "11/19/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06065": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "multimedia",
            "displayTitle": "multimedia",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|multimedia"
        },
        "component": {
            "name": "cta",
            "title": "clinical experience",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06065",
            "atsRecordKey": "[dynamic]_ats06065",
            "created": "11/19/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06063": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Bipolar I Disorder Disease State Education",
            "displayTitle": "Bipolar I Disorder Disease State Education",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|bipolar i disorder disease state education"
        },
        "component": {
            "name": "cta",
            "title": "bipolar|disorder resources",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06063",
            "atsRecordKey": "[dynamic]_ats06063",
            "created": "11/19/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06059": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "quick guide for providers",
            "displayTitle": "quick guide for providers",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|quick guide for providers"
        },
        "component": {
            "name": "cta",
            "title": "bipolar|disorder resources",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06059",
            "atsRecordKey": "[dynamic]_ats06059",
            "created": "11/19/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06066": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "clinical article summaries",
            "displayTitle": "clinical article summaries",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|clinical article summaries"
        },
        "component": {
            "name": "cta",
            "title": "bipolar|disorder resources",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06066",
            "atsRecordKey": "[dynamic]_ats06066",
            "created": "11/19/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06069": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "contact MSL",
            "displayTitle": "contact MSL",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||navigation|contact msl"
        },
        "component": {
            "name": "cta",
            "title": "contact MSL",
            "position": "header",
            "type": "navigation",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06069",
            "atsRecordKey": "[dynamic]_ats06069",
            "created": "11/19/24 3:35 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06067": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06067",
            "atsRecordKey": "[dynamic]_ats06067",
            "created": "11/19/24 3:35 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06068": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06068",
            "atsRecordKey": "[dynamic]_ats06068",
            "created": "11/19/24 3:35 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06070": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "about",
            "displayTitle": "about",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|about"
        },
        "component": {
            "name": "footer",
            "title": "about",
            "position": "footer",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06070",
            "atsRecordKey": "[dynamic]_ats06070",
            "created": "11/19/24 3:37 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06084": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06084",
            "atsRecordKey": "[dynamic]_ats06084",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06086": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06086",
            "atsRecordKey": "[dynamic]_ats06086",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06072": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "accessibility staement",
            "displayTitle": "accessibility staement",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|accessibility staement"
        },
        "component": {
            "name": "footer",
            "title": "accessibility staement",
            "position": "footer",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06072",
            "atsRecordKey": "[dynamic]_ats06072",
            "created": "11/19/24 3:37 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06078": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06078",
            "atsRecordKey": "[dynamic]_ats06078",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06079": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06079",
            "atsRecordKey": "[dynamic]_ats06079",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06071": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "contact us",
            "displayTitle": "contact us",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|contact us"
        },
        "component": {
            "name": "footer",
            "title": "contact us",
            "position": "footer",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06071",
            "atsRecordKey": "[dynamic]_ats06071",
            "created": "11/19/24 3:37 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06073": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06073",
            "atsRecordKey": "[dynamic]_ats06073",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06074": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06074",
            "atsRecordKey": "[dynamic]_ats06074",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06075": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "terms of use",
            "displayTitle": "terms of use",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|terms of use"
        },
        "component": {
            "name": "footer",
            "title": "terms of use",
            "position": "footer",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06075",
            "atsRecordKey": "[dynamic]_ats06075",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06082": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06082",
            "atsRecordKey": "[dynamic]_ats06082",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06083": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06083",
            "atsRecordKey": "[dynamic]_ats06083",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06081": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "privacy notice",
            "displayTitle": "privacy notice",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|privacy notice"
        },
        "component": {
            "name": "footer",
            "title": "privacy notice",
            "position": "footer",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06081",
            "atsRecordKey": "[dynamic]_ats06081",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06076": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06076",
            "atsRecordKey": "[dynamic]_ats06076",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06077": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06077",
            "atsRecordKey": "[dynamic]_ats06077",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06085": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "cookie settings",
            "displayTitle": "cookie settings",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|cookie settings"
        },
        "component": {
            "name": "footer",
            "title": "cookie settings",
            "position": "footer",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06085",
            "atsRecordKey": "[dynamic]_ats06085",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06080": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "your privacy choices",
            "displayTitle": "your privacy choices",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|your privacy choices"
        },
        "component": {
            "name": "footer",
            "title": "your privacy choices",
            "position": "footer",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06080",
            "atsRecordKey": "[dynamic]_ats06080",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06087": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06087",
            "atsRecordKey": "[dynamic]_ats06087",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06088": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||footer|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "footer",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06088",
            "atsRecordKey": "[dynamic]_ats06088",
            "created": "11/19/24 3:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06266": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "[all pages- page name]",
            "prefix": "[dynamic]",
            "url": "[All Pages URL]",
            "path": "[All Pages URL]",
            "pageTaxonomyName": "[dynamic]|ub|neuro|bd|[all pages- page name]"
        },
        "category": {
            "primaryCategory": "[dynamic]",
            "subCategory1": "[dynamic]",
            "subCategory2": "[dynamic]"
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "[dynamic]",
            "globalISI": "[dynamic]",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "[dynamic] |"
        },
        "journey": {
            "content": "[dynamic]",
            "patient": "[dynamic]",
            "messageBucket": "[dynamic]"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "references",
            "displayTitle": "references",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "[dynamic]|ub|bd|neuro|[all pages- page name]||content|references"
        },
        "component": {
            "name": "accordion",
            "title": "references",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06266",
            "atsRecordKey": "[dynamic]_ats06266",
            "created": "11/20/24 4:29 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06096": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Rapid Mood Screener tool video is clicked to Start"
        },
        "link": {
            "name": "Rapid Mood Screener tool video - start",
            "displayTitle": "Rapid Mood Screener tool video - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|rapid mood screener tool video - start"
        },
        "component": {
            "name": "video player",
            "title": "Rapid Mood Screener tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Rapid Mood Screener tool video - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "homepage rms tool video",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Homepage RMS Tool Video",
            "nameTitle": "homepage rms tool video",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06096",
            "atsRecordKey": "rapidmodscreener_ats06096",
            "created": "11/19/24 4:21 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 8:49 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06102": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay Rapid Mood Screener tool video"
        },
        "link": {
            "name": "Rapid Mood Screener tool video - replay",
            "displayTitle": "Rapid Mood Screener tool video - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|rapid mood screener tool video - replay"
        },
        "component": {
            "name": "video player",
            "title": "Rapid Mood Screener tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Rapid Mood Screener tool video - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06102",
            "atsRecordKey": "rapidmodscreener_ats06102",
            "created": "11/19/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06101": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Rapid Mood Screener tool video is 25% completed"
        },
        "link": {
            "name": "Rapid Mood Screener tool video - 25",
            "displayTitle": "Rapid Mood Screener tool video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|rapid mood screener tool video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Rapid Mood Screener tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Rapid Mood Screener tool video - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06101",
            "atsRecordKey": "rapidmodscreener_ats06101",
            "created": "11/19/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06100": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Rapid Mood Screener tool video is 50% completed"
        },
        "link": {
            "name": "Rapid Mood Screener tool video - 50",
            "displayTitle": "Rapid Mood Screener tool video - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|rapid mood screener tool video - 50"
        },
        "component": {
            "name": "video player",
            "title": "Rapid Mood Screener tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Rapid Mood Screener tool video - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06100",
            "atsRecordKey": "rapidmodscreener_ats06100",
            "created": "11/19/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06099": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Rapid Mood Screener tool video is 75% completed"
        },
        "link": {
            "name": "Rapid Mood Screener tool video - 75",
            "displayTitle": "Rapid Mood Screener tool video - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|rapid mood screener tool video - 75"
        },
        "component": {
            "name": "video player",
            "title": "Rapid Mood Screener tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Rapid Mood Screener tool video - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06099",
            "atsRecordKey": "rapidmodscreener_ats06099",
            "created": "11/19/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06098": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Rapid Mood Screener tool video is Ended (100%)"
        },
        "link": {
            "name": "Rapid Mood Screener tool video - End",
            "displayTitle": "Rapid Mood Screener tool video - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|rapid mood screener tool video - end"
        },
        "component": {
            "name": "video player",
            "title": "Rapid Mood Screener tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Rapid Mood Screener tool video - End"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06098",
            "atsRecordKey": "rapidmodscreener_ats06098",
            "created": "11/19/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06097": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause Rapid Mood Screener tool video"
        },
        "link": {
            "name": "Rapid Mood Screener tool video - pause",
            "displayTitle": "Rapid Mood Screener tool video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|rapid mood screener tool video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Rapid Mood Screener tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Rapid Mood Screener tool video - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06097",
            "atsRecordKey": "rapidmodscreener_ats06097",
            "created": "11/19/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06103": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume Rapid Mood Screener tool video"
        },
        "link": {
            "name": "Rapid Mood Screener tool video - resume",
            "displayTitle": "Rapid Mood Screener tool video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|rapid mood screener tool video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Rapid Mood Screener tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Rapid Mood Screener tool video - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06103",
            "atsRecordKey": "rapidmodscreener_ats06103",
            "created": "11/19/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06104": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "journal website",
            "displayTitle": "journal website",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|journal website"
        },
        "component": {
            "name": "cta",
            "title": "About the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06104",
            "atsRecordKey": "rapidmodscreener_ats06104",
            "created": "11/19/24 4:28 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06105": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06105",
            "atsRecordKey": "rapidmodscreener_ats06105",
            "created": "11/19/24 4:29 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06106": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06106",
            "atsRecordKey": "rapidmodscreener_ats06106",
            "created": "11/19/24 4:29 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 1:48 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06107": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "homepage",
            "prefix": "rapidmodscreener",
            "url": "/",
            "path": "/",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|homepage"
        },
        "category": {
            "primaryCategory": "homepage",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Homepage",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "MVA should fire once user clicks \"Access Tool"
        },
        "link": {
            "name": "access tool",
            "displayTitle": "access tool",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|homepage||content|access tool"
        },
        "component": {
            "name": "cta",
            "title": "About the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Tool",
            "category": "Workaround Quiz",
            "name": "homepage access tool button",
            "typeAndCategory": "Tool|Workaround Quiz",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Homepage Access Tool Button",
            "nameTitle": "homepage access tool button",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06107",
            "atsRecordKey": "rapidmodscreener_ats06107",
            "created": "11/19/24 4:33 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 8:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06109": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "start",
            "displayTitle": "start",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|start"
        },
        "component": {
            "name": "cta",
            "title": "Patient Case Navigator",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06109",
            "atsRecordKey": "rapidmodscreener_ats06109",
            "created": "11/19/24 5:07 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06116": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "previous",
            "displayTitle": "previous",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|previous"
        },
        "component": {
            "name": "cta",
            "title": "Patient Case Navigator",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06116",
            "atsRecordKey": "rapidmodscreener_ats06116",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06117": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|next"
        },
        "component": {
            "name": "cta",
            "title": "Patient Case Navigator",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06117",
            "atsRecordKey": "rapidmodscreener_ats06117",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06118": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "reset",
            "displayTitle": "reset",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|reset"
        },
        "component": {
            "name": "cta",
            "title": "Patient Case Navigator",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06118",
            "atsRecordKey": "rapidmodscreener_ats06118",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06119": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is clicked to Start"
        },
        "link": {
            "name": "differentiating bipolar introduction video - start",
            "displayTitle": "differentiating bipolar video - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - start"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introduction",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar video - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "interactive case study video play",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Video Play",
            "nameTitle": "interactive case study video play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06119",
            "atsRecordKey": "rapidmodscreener_ats06119",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06120": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar introduction video - replay",
            "displayTitle": "differentiating bipolar video - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - replay"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introduction",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar video - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06120",
            "atsRecordKey": "rapidmodscreener_ats06120",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06121": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 25% completed"
        },
        "link": {
            "name": "differentiating bipolar introduction video - 25",
            "displayTitle": "differentiating bipolar video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introduction",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar video - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06121",
            "atsRecordKey": "rapidmodscreener_ats06121",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06122": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 50% completed"
        },
        "link": {
            "name": "differentiating bipolar introduction video - 50",
            "displayTitle": "differentiating bipolar video - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 50"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introduction",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar video - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06122",
            "atsRecordKey": "rapidmodscreener_ats06122",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06123": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 75% completed"
        },
        "link": {
            "name": "differentiating bipolar introduction video - 75",
            "displayTitle": "differentiating bipolar video - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 75"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introduction",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar video - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06123",
            "atsRecordKey": "rapidmodscreener_ats06123",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06124": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is Ended (100%)"
        },
        "link": {
            "name": "differentiating bipolar introduction video - End",
            "displayTitle": "differentiating bipolar video - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - end"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introduction",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar video - End"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06124",
            "atsRecordKey": "rapidmodscreener_ats06124",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06113": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar introduction video - pause",
            "displayTitle": "differentiating bipolar video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introduction",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar video - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06113",
            "atsRecordKey": "rapidmodscreener_ats06113",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06130": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar introduction video - resume",
            "displayTitle": "differentiating bipolar video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introduction",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar video - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06130",
            "atsRecordKey": "rapidmodscreener_ats06130",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06115": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "History of Present Illness",
            "displayTitle": "History of Present Illness",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|history of present illness"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06115",
            "atsRecordKey": "rapidmodscreener_ats06115",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06128": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Medical History",
            "displayTitle": "Medical History",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|medical history"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06128",
            "atsRecordKey": "rapidmodscreener_ats06128",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06112": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Timeline of Jenna's Present Illness",
            "displayTitle": "Timeline of Jenna's Present Illness",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|timeline of jenna's present illness"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06112",
            "atsRecordKey": "rapidmodscreener_ats06112",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06125": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is clicked to Start"
        },
        "link": {
            "name": "differentiating bipolar - Patient Chart Review\nvideo - start",
            "displayTitle": "differentiating bipolar video - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - start"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Chart Review\nvideo - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "interactive case study video play",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Video Play",
            "nameTitle": "interactive case study video play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06125",
            "atsRecordKey": "rapidmodscreener_ats06125",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06126": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Patient Chart Review video - replay",
            "displayTitle": "differentiating bipolar video - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - replay"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Chart Review video - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06126",
            "atsRecordKey": "rapidmodscreener_ats06126",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06127": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 25% completed"
        },
        "link": {
            "name": "differentiating bipolar - Patient Chart Review video - 25",
            "displayTitle": "differentiating bipolar video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Chart Review video - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06127",
            "atsRecordKey": "rapidmodscreener_ats06127",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06129": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 50% completed"
        },
        "link": {
            "name": "differentiating bipolar - Patient Chart Review video - 50",
            "displayTitle": "differentiating bipolar video - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 50"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Chart Review video - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06129",
            "atsRecordKey": "rapidmodscreener_ats06129",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06131": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 75% completed"
        },
        "link": {
            "name": "differentiating bipolar - Patient Chart Review video - 75",
            "displayTitle": "differentiating bipolar video - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 75"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Chart Review video - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06131",
            "atsRecordKey": "rapidmodscreener_ats06131",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06110": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is Ended (100%)"
        },
        "link": {
            "name": "differentiating bipolar - Patient Chart Review video - End",
            "displayTitle": "differentiating bipolar video - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - end"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Chart Review video - End"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06110",
            "atsRecordKey": "rapidmodscreener_ats06110",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06111": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Patient Chart Review video - pause",
            "displayTitle": "differentiating bipolar video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Chart Review video - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06111",
            "atsRecordKey": "rapidmodscreener_ats06111",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06114": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Patient Chart Review video - resume",
            "displayTitle": "differentiating bipolar video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Chart Review",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Chart Review video - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06114",
            "atsRecordKey": "rapidmodscreener_ats06114",
            "created": "11/19/24 6:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06132": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Current Symptoms",
            "displayTitle": "Current Symptoms",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|current symptoms"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06132",
            "atsRecordKey": "rapidmodscreener_ats06132",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06133": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "history of episodes",
            "displayTitle": "history of episodes",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|history of episodes"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06133",
            "atsRecordKey": "rapidmodscreener_ats06133",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06134": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is clicked to Start"
        },
        "link": {
            "name": "differentiating bipolar - Patient Interview video - start",
            "displayTitle": "differentiating bipolar video - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - start"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Interview video - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "interactive case study video play",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Video Play",
            "nameTitle": "interactive case study video play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06134",
            "atsRecordKey": "rapidmodscreener_ats06134",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06135": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Patient Interview video - replay",
            "displayTitle": "differentiating bipolar video - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - replay"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Interview video - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06135",
            "atsRecordKey": "rapidmodscreener_ats06135",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06136": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 25% completed"
        },
        "link": {
            "name": "differentiating bipolar - Patient Interview video - 25",
            "displayTitle": "differentiating bipolar video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Interview video - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06136",
            "atsRecordKey": "rapidmodscreener_ats06136",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06137": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 50% completed"
        },
        "link": {
            "name": "differentiating bipolar - Patient Interview video - 50",
            "displayTitle": "differentiating bipolar video - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 50"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Interview video - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06137",
            "atsRecordKey": "rapidmodscreener_ats06137",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06138": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 75% completed"
        },
        "link": {
            "name": "differentiating bipolar - Patient Interview video - 75",
            "displayTitle": "differentiating bipolar video - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 75"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Interview video - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06138",
            "atsRecordKey": "rapidmodscreener_ats06138",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06139": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is Ended (100%)"
        },
        "link": {
            "name": "differentiating bipolar - Patient Interview video - End",
            "displayTitle": "differentiating bipolar video - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - end"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Interview video - End"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06139",
            "atsRecordKey": "rapidmodscreener_ats06139",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06140": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Patient Interview video - pause",
            "displayTitle": "differentiating bipolar video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Interview video - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06140",
            "atsRecordKey": "rapidmodscreener_ats06140",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06141": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Patient Interview video - resume",
            "displayTitle": "differentiating bipolar video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Patient Interview",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Patient Interview video - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06141",
            "atsRecordKey": "rapidmodscreener_ats06141",
            "created": "11/19/24 6:38 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06142": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "Based on what Jenna is reporting quiz",
            "displayTitle": "Based on what Jenna is reporting quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|based on what jenna is reporting quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Based on what Jenna is reporting",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Form",
            "category": "Symptom Quiz",
            "name": "interactive case study video play",
            "typeAndCategory": "Form|Symptom Quiz",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Video Play",
            "nameTitle": "interactive case study video play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06142",
            "atsRecordKey": "rapidmodscreener_ats06142",
            "created": "11/19/24 6:45 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06143": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06143",
            "atsRecordKey": "rapidmodscreener_ats06143",
            "created": "11/19/24 6:45 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06144": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06144",
            "atsRecordKey": "rapidmodscreener_ats06144",
            "created": "11/19/24 6:45 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06145": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06145",
            "atsRecordKey": "rapidmodscreener_ats06145",
            "created": "11/19/24 6:45 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06149": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "What are topics that should be inquired quiz",
            "displayTitle": "What are topics that should be inquired quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|what are topics that should be inquired quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "What are topics that should be inquired",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06149",
            "atsRecordKey": "rapidmodscreener_ats06149",
            "created": "11/19/24 6:59 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06156": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "choose",
            "displayTitle": "choose",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|choose"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "choose",
            "fieldValue": "choose",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06156",
            "atsRecordKey": "rapidmodscreener_ats06156",
            "created": "11/20/24 1:03 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06150": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06150",
            "atsRecordKey": "rapidmodscreener_ats06150",
            "created": "11/19/24 6:59 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06151": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06151",
            "atsRecordKey": "rapidmodscreener_ats06151",
            "created": "11/19/24 6:59 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06152": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06152",
            "atsRecordKey": "rapidmodscreener_ats06152",
            "created": "11/19/24 6:59 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06153": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "Based on Jenna\u2019s case quiz",
            "displayTitle": "Based on Jenna\u2019s case quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|based on jenna\u2019s case quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Based on Jenna's case",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06153",
            "atsRecordKey": "rapidmodscreener_ats06153",
            "created": "11/19/24 6:59 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06146": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06146",
            "atsRecordKey": "rapidmodscreener_ats06146",
            "created": "11/19/24 6:59 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06147": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06147",
            "atsRecordKey": "rapidmodscreener_ats06147",
            "created": "11/19/24 6:59 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06148": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 1",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06148",
            "atsRecordKey": "rapidmodscreener_ats06148",
            "created": "11/19/24 6:59 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06157": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is clicked to Start"
        },
        "link": {
            "name": "differentiating bipolar - Current Medical History - start",
            "displayTitle": "differentiating bipolar video - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - start"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Current Medical History",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Current Medical History - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "interactive case study video play",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Video Play",
            "nameTitle": "interactive case study video play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06157",
            "atsRecordKey": "rapidmodscreener_ats06157",
            "created": "11/20/24 1:08 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06158": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Current Medical History - replay",
            "displayTitle": "differentiating bipolar video - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - replay"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Current Medical History",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Current Medical History - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06158",
            "atsRecordKey": "rapidmodscreener_ats06158",
            "created": "11/20/24 1:08 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06159": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 25% completed"
        },
        "link": {
            "name": "differentiating bipolar - Current Medical History - 25",
            "displayTitle": "differentiating bipolar video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Current Medical History",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Current Medical History - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06159",
            "atsRecordKey": "rapidmodscreener_ats06159",
            "created": "11/20/24 1:08 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06160": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 50% completed"
        },
        "link": {
            "name": "differentiating bipolar - Current Medical History - 50",
            "displayTitle": "differentiating bipolar video - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 50"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Current Medical History",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Current Medical History - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06160",
            "atsRecordKey": "rapidmodscreener_ats06160",
            "created": "11/20/24 1:08 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06161": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 75% completed"
        },
        "link": {
            "name": "differentiating bipolar - Current Medical History - 75",
            "displayTitle": "differentiating bipolar video - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 75"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Current Medical History",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Current Medical History - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06161",
            "atsRecordKey": "rapidmodscreener_ats06161",
            "created": "11/20/24 1:08 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06162": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is Ended (100%)"
        },
        "link": {
            "name": "differentiating bipolar - Current Medical History - End",
            "displayTitle": "differentiating bipolar video - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - end"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Current Medical History",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Current Medical History - End"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06162",
            "atsRecordKey": "rapidmodscreener_ats06162",
            "created": "11/20/24 1:08 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06163": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Current Medical History - pause",
            "displayTitle": "differentiating bipolar video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Current Medical History",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Current Medical History - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06163",
            "atsRecordKey": "rapidmodscreener_ats06163",
            "created": "11/20/24 1:08 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06164": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Current Medical History - resume",
            "displayTitle": "differentiating bipolar video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Current Medical History",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Current Medical History - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06164",
            "atsRecordKey": "rapidmodscreener_ats06164",
            "created": "11/20/24 1:08 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:33 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06180": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "Which of the following is/are recommendations Quiz",
            "displayTitle": "Which of the following is/are recommendations Quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|which of the following is/are recommendations quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Which of the following is/are recommendations",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Form",
            "category": "Symptom Quiz",
            "name": "interactive case study quiz interaction",
            "typeAndCategory": "Form|Symptom Quiz",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Quiz Interaction",
            "nameTitle": "interactive case study quiz interaction",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06180",
            "atsRecordKey": "rapidmodscreener_ats06180",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06192": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "choose",
            "displayTitle": "choose",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|choose"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "choose",
            "fieldValue": "choose",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06192",
            "atsRecordKey": "rapidmodscreener_ats06192",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:38 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06181": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06181",
            "atsRecordKey": "rapidmodscreener_ats06181",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:38 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06183": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06183",
            "atsRecordKey": "rapidmodscreener_ats06183",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:38 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06184": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06184",
            "atsRecordKey": "rapidmodscreener_ats06184",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:38 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06187": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "From Tony\u2019s interview with Jenna on her previous mood swings quiz",
            "displayTitle": "From Tony\u2019s interview with Jenna on her previous mood swings quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|from tony\u2019s interview with jenna on her previous mood swings quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "From Tony's interview with Jenna on her previous mood swings",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06187",
            "atsRecordKey": "rapidmodscreener_ats06187",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06216": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "choose",
            "displayTitle": "choose",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|choose"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "choose",
            "fieldValue": "choose",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06216",
            "atsRecordKey": "rapidmodscreener_ats06216",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06188": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06188",
            "atsRecordKey": "rapidmodscreener_ats06188",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06190": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06190",
            "atsRecordKey": "rapidmodscreener_ats06190",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06191": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06191",
            "atsRecordKey": "rapidmodscreener_ats06191",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06165": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "True or false quiz",
            "displayTitle": "True or false quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|true or false quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "True or false",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06165",
            "atsRecordKey": "rapidmodscreener_ats06165",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06166": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06166",
            "atsRecordKey": "rapidmodscreener_ats06166",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06168": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06168",
            "atsRecordKey": "rapidmodscreener_ats06168",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06169": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 2",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06169",
            "atsRecordKey": "rapidmodscreener_ats06169",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06170": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Screening for BP-1",
            "displayTitle": "Screening for BP-1",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|screening for bp-1"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06170",
            "atsRecordKey": "rapidmodscreener_ats06170",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06171": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Rapid Mood Screener",
            "displayTitle": "Rapid Mood Screener",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|rapid mood screener"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06171",
            "atsRecordKey": "rapidmodscreener_ats06171",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06185": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "New Diagnosis",
            "displayTitle": "New Diagnosis",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|new diagnosis"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06185",
            "atsRecordKey": "rapidmodscreener_ats06185",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06186": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "BP-1 Symptoms Prevalence",
            "displayTitle": "BP-1 Symptoms Prevalence",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|bp-1 symptoms prevalence"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06186",
            "atsRecordKey": "rapidmodscreener_ats06186",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06172": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is clicked to Start"
        },
        "link": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - start",
            "displayTitle": "differentiating bipolar video - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - start"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "interactive case study video play",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Video Play",
            "nameTitle": "interactive case study video play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06172",
            "atsRecordKey": "rapidmodscreener_ats06172",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06173": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - replay",
            "displayTitle": "differentiating bipolar video - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - replay"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06173",
            "atsRecordKey": "rapidmodscreener_ats06173",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06174": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 25% completed"
        },
        "link": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - 25",
            "displayTitle": "differentiating bipolar video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06174",
            "atsRecordKey": "rapidmodscreener_ats06174",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06175": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 50% completed"
        },
        "link": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - 50",
            "displayTitle": "differentiating bipolar video - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 50"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06175",
            "atsRecordKey": "rapidmodscreener_ats06175",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06176": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 75% completed"
        },
        "link": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - 75",
            "displayTitle": "differentiating bipolar video - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 75"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06176",
            "atsRecordKey": "rapidmodscreener_ats06176",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06177": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is Ended (100%)"
        },
        "link": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - End",
            "displayTitle": "differentiating bipolar video - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - end"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - End"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06177",
            "atsRecordKey": "rapidmodscreener_ats06177",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06178": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - pause",
            "displayTitle": "differentiating bipolar video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06178",
            "atsRecordKey": "rapidmodscreener_ats06178",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06179": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - resume",
            "displayTitle": "differentiating bipolar video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Introducing the Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Introducing the Rapid Mood Screener video - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06179",
            "atsRecordKey": "rapidmodscreener_ats06179",
            "created": "11/20/24 3:34 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 2:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06198": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "Which of the following is/are  factors Quiz",
            "displayTitle": "Which of the following is/are  factors Quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|which of the following is/are  factors quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Which of the following is/are  factors",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Form",
            "category": "Symptom Quiz",
            "name": "interactive case study quiz interaction",
            "typeAndCategory": "Form|Symptom Quiz",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Quiz Interaction",
            "nameTitle": "interactive case study quiz interaction",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06198",
            "atsRecordKey": "rapidmodscreener_ats06198",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06319": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "choose",
            "displayTitle": "choose",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|choose"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "choose",
            "fieldValue": "choose",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06319",
            "atsRecordKey": "rapidmodscreener_ats06319",
            "created": "11/22/24 3:08 AM",
            "createdBy": "anitha.kollipara@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06200": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06200",
            "atsRecordKey": "rapidmodscreener_ats06200",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06202": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06202",
            "atsRecordKey": "rapidmodscreener_ats06202",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06204": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06204",
            "atsRecordKey": "rapidmodscreener_ats06204",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06193": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "What is/are (a) possible consequence(s) of misdiagnosing bipolar I disorder quiz",
            "displayTitle": "What is/are (a) possible consequence(s) of misdiagnosing bipolar I disorder quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|what is/are (a) possible consequence(s) of misdiagnosing bipolar i disorder quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "What is/are (a) possible consequence(s) of misdiagnosing bipolar I disorder",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06193",
            "atsRecordKey": "rapidmodscreener_ats06193",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06194": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "choose",
            "displayTitle": "choose",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|choose"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "choose",
            "fieldValue": "choose",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06194",
            "atsRecordKey": "rapidmodscreener_ats06194",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06195": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06195",
            "atsRecordKey": "rapidmodscreener_ats06195",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06196": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06196",
            "atsRecordKey": "rapidmodscreener_ats06196",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06197": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06197",
            "atsRecordKey": "rapidmodscreener_ats06197",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06217": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "Which of the following does NOT accurately describe the Rapid Mood Screener quiz",
            "displayTitle": "Which of the following does NOT accurately describe the Rapid Mood Screener quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|which of the following does not accurately describe the rapid mood screener quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Which of the following does NOT accurately describe the Rapid Mood Screener",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06217",
            "atsRecordKey": "rapidmodscreener_ats06217",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06320": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "choose",
            "displayTitle": "choose",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|choose"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "choose",
            "fieldValue": "choose",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06320",
            "atsRecordKey": "rapidmodscreener_ats06320",
            "created": "11/22/24 3:08 AM",
            "createdBy": "anitha.kollipara@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06218": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06218",
            "atsRecordKey": "rapidmodscreener_ats06218",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06219": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06219",
            "atsRecordKey": "rapidmodscreener_ats06219",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06220": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06220",
            "atsRecordKey": "rapidmodscreener_ats06220",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06208": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "Which of the following does NOT accurately describe the Rapid Mood Screener quiz",
            "displayTitle": "Which of the following clinical features prompted Tony quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|which of the following clinical features prompted tony quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Which of the following clinical features prompted Tony",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06208",
            "atsRecordKey": "rapidmodscreener_ats06208",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06211": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "choose",
            "displayTitle": "choose",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|choose"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "choose",
            "fieldValue": "choose",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06211",
            "atsRecordKey": "rapidmodscreener_ats06211",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06212": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06212",
            "atsRecordKey": "rapidmodscreener_ats06212",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06214": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06214",
            "atsRecordKey": "rapidmodscreener_ats06214",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06215": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 3",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06215",
            "atsRecordKey": "rapidmodscreener_ats06215",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06199": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Management Plan",
            "displayTitle": "Management Plan",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|management plan"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06199",
            "atsRecordKey": "rapidmodscreener_ats06199",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06201": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is clicked to Start"
        },
        "link": {
            "name": "differentiating bipolar - Jenna's Management Plan video - start",
            "displayTitle": "differentiating bipolar video - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - start"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Jenna's Management Plan video - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "interactive case study video play",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Video Play",
            "nameTitle": "interactive case study video play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06201",
            "atsRecordKey": "rapidmodscreener_ats06201",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06203": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Jenna's Management Plan video - replay",
            "displayTitle": "differentiating bipolar video - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - replay"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Jenna's Management Plan video - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06203",
            "atsRecordKey": "rapidmodscreener_ats06203",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06205": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 25% completed"
        },
        "link": {
            "name": "differentiating bipolar - Jenna's Management Plan video - 25",
            "displayTitle": "differentiating bipolar video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Jenna's Management Plan video - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06205",
            "atsRecordKey": "rapidmodscreener_ats06205",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06206": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 50% completed"
        },
        "link": {
            "name": "differentiating bipolar - Jenna's Management Plan video - 50",
            "displayTitle": "differentiating bipolar video - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 50"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Jenna's Management Plan video - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06206",
            "atsRecordKey": "rapidmodscreener_ats06206",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06207": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 75% completed"
        },
        "link": {
            "name": "differentiating bipolar - Jenna's Management Plan video - 75",
            "displayTitle": "differentiating bipolar video - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 75"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Jenna's Management Plan video - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06207",
            "atsRecordKey": "rapidmodscreener_ats06207",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06209": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is Ended (100%)"
        },
        "link": {
            "name": "differentiating bipolar - Jenna's Management Plan video - end",
            "displayTitle": "differentiating bipolar video - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - end"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Jenna's Management Plan video - end"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06209",
            "atsRecordKey": "rapidmodscreener_ats06209",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06210": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Jenna's Management Plan video - pause",
            "displayTitle": "differentiating bipolar video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Jenna's Management Plan video - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06210",
            "atsRecordKey": "rapidmodscreener_ats06210",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06213": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Jenna's Management Plan video - resume",
            "displayTitle": "differentiating bipolar video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Jenna's Management Plan",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Jenna's Management Plan video - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06213",
            "atsRecordKey": "rapidmodscreener_ats06213",
            "created": "11/20/24 3:48 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06222": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "True or false quiz",
            "displayTitle": "True or false quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|true or false quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "True or false",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Form",
            "category": "Symptom Quiz",
            "name": "interactive case study quiz interaction",
            "typeAndCategory": "Form|Symptom Quiz",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Quiz Interaction",
            "nameTitle": "interactive case study quiz interaction",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06222",
            "atsRecordKey": "rapidmodscreener_ats06222",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06223": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06223",
            "atsRecordKey": "rapidmodscreener_ats06223",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06225": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06225",
            "atsRecordKey": "rapidmodscreener_ats06225",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06228": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06228",
            "atsRecordKey": "rapidmodscreener_ats06228",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06229": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "Which of the following does NOT accurately describe the Rapid Mood Screener quiz",
            "displayTitle": "Which of the following statements accurately describe treatment options quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|which of the following statements accurately describe treatment options quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Which of the following statements accurately describe treatment options",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06229",
            "atsRecordKey": "rapidmodscreener_ats06229",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06221": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "choose",
            "displayTitle": "choose",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|choose"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "choose",
            "fieldValue": "choose",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06221",
            "atsRecordKey": "rapidmodscreener_ats06221",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06224": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06224",
            "atsRecordKey": "rapidmodscreener_ats06224",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06226": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06226",
            "atsRecordKey": "rapidmodscreener_ats06226",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06227": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Patient Case Navigator - Quiz 4",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06227",
            "atsRecordKey": "rapidmodscreener_ats06227",
            "created": "11/20/24 3:50 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:08 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06231": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Summary Points",
            "displayTitle": "Summary Points",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|summary points"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06231",
            "atsRecordKey": "rapidmodscreener_ats06231",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06230": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "predictive factors",
            "displayTitle": "predictive factors",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|predictive factors"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06230",
            "atsRecordKey": "rapidmodscreener_ats06230",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06242": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "manic symptoms",
            "displayTitle": "manic symptoms",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|manic symptoms"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06242",
            "atsRecordKey": "rapidmodscreener_ats06242",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06241": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "RMS",
            "displayTitle": "RMS",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|rms"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06241",
            "atsRecordKey": "rapidmodscreener_ats06241",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06233": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Treatment Options",
            "displayTitle": "Treatment Options",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|treatment options"
        },
        "component": {
            "name": "tabs",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06233",
            "atsRecordKey": "rapidmodscreener_ats06233",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06232": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is clicked to Start"
        },
        "link": {
            "name": "differentiating bipolar - Conclusion video - start",
            "displayTitle": "differentiating bipolar video - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - start"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Conclusion video - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "interactive case study video play",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Interactive Case Study Video Play",
            "nameTitle": "interactive case study video play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06232",
            "atsRecordKey": "rapidmodscreener_ats06232",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06234": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Conclusion video - replay",
            "displayTitle": "differentiating bipolar video - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - replay"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Conclusion video - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06234",
            "atsRecordKey": "rapidmodscreener_ats06234",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06235": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 25% completed"
        },
        "link": {
            "name": "differentiating bipolar - Conclusion video - 25",
            "displayTitle": "differentiating bipolar video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Conclusion video - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06235",
            "atsRecordKey": "rapidmodscreener_ats06235",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06236": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 50% completed"
        },
        "link": {
            "name": "differentiating bipolar - Conclusion video - 50",
            "displayTitle": "differentiating bipolar video - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 50"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Conclusion video - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06236",
            "atsRecordKey": "rapidmodscreener_ats06236",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06237": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is 75% completed"
        },
        "link": {
            "name": "differentiating bipolar - Conclusion video - 75",
            "displayTitle": "differentiating bipolar video - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - 75"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Conclusion video - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06237",
            "atsRecordKey": "rapidmodscreener_ats06237",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06238": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When differentiating bipolar video is Ended (100%)"
        },
        "link": {
            "name": "differentiating bipolar - Conclusion video - end",
            "displayTitle": "differentiating bipolar video - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - end"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Conclusion video - end"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06238",
            "atsRecordKey": "rapidmodscreener_ats06238",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06239": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Conclusion video - pause",
            "displayTitle": "differentiating bipolar video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Conclusion video - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06239",
            "atsRecordKey": "rapidmodscreener_ats06239",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06240": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume differentiating bipolar video"
        },
        "link": {
            "name": "differentiating bipolar - Conclusion video - resume",
            "displayTitle": "differentiating bipolar video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|differentiating bipolar video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Patient Case Navigator - Conclusion",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "differentiating bipolar - Conclusion video - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06240",
            "atsRecordKey": "rapidmodscreener_ats06240",
            "created": "11/20/24 4:09 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06243": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "case study 1",
            "prefix": "rapidmodscreener",
            "url": "/case-studies/case-study-1",
            "path": "/case-studies/case-study-1",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|case study 1"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "click here to start over",
            "displayTitle": "click here to start over",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|case study 1||content|click here to start over"
        },
        "component": {
            "name": "cta",
            "title": "Patient Case Navigator - thank you for completing",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06243",
            "atsRecordKey": "rapidmodscreener_ats06243",
            "created": "11/20/24 4:15 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 3:28 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06244": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "patient case quizzes",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes",
            "path": "/patient-case-quizzes",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|patient case quizzes"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "take quiz",
            "displayTitle": "take quiz",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|patient case quizzes||content|take quiz"
        },
        "component": {
            "name": "cta",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06244",
            "atsRecordKey": "rapidmodscreener_ats06244",
            "created": "11/20/24 4:19 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06260": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "Patient Case Quizzes on breadcrumb"
        },
        "link": {
            "name": "Patient Case Quizzes",
            "displayTitle": "Patient Case Quizzes",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|patient case quizzes"
        },
        "component": {
            "name": "cta",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06260",
            "atsRecordKey": "rapidmodscreener_ats06260",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06278": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "MVA should fire once user selects and answer"
        },
        "link": {
            "name": "Why is Mark\u2019s medical history important for his di\ufb00erential diagnosis quiz",
            "displayTitle": "Why is Mark\u2019s medical history important for his di\ufb00erential diagnosis quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|why is mark\u2019s medical history important for his di\ufb00erential diagnosis quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Which of the following statements accurately describe treatment options",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Form",
            "category": "Symptom Quiz",
            "name": "patient case quiz interaction",
            "typeAndCategory": "Form|Symptom Quiz",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Patient Case Quiz Interaction",
            "nameTitle": "patient case quiz interaction",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06278",
            "atsRecordKey": "rapidmodscreener_ats06278",
            "created": "11/20/24 4:39 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 8:54 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06261": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06261",
            "atsRecordKey": "rapidmodscreener_ats06261",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06262": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06262",
            "atsRecordKey": "rapidmodscreener_ats06262",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06263": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06263",
            "atsRecordKey": "rapidmodscreener_ats06263",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06264": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "MVA should fire once user selects and answer"
        },
        "link": {
            "name": "Which features would likely indicate a diagnosis of bipolar quiz",
            "displayTitle": "Which features would likely indicate a diagnosis of bipolar quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|which features would likely indicate a diagnosis of bipolar quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Which features would likely indicate a diagnosis of bipolar",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06264",
            "atsRecordKey": "rapidmodscreener_ats06264",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06247": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06247",
            "atsRecordKey": "rapidmodscreener_ats06247",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06248": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06248",
            "atsRecordKey": "rapidmodscreener_ats06248",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06250": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06250",
            "atsRecordKey": "rapidmodscreener_ats06250",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06252": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "MVA should fire once user selects and answer"
        },
        "link": {
            "name": "Does Mark\u2019s clinical presentation include any manic features quiz",
            "displayTitle": "Does Mark\u2019s clinical presentation include any manic features quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|does mark\u2019s clinical presentation include any manic features quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Does Mark\u2019s clinical presentation include any manic features",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06252",
            "atsRecordKey": "rapidmodscreener_ats06252",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06253": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06253",
            "atsRecordKey": "rapidmodscreener_ats06253",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06254": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06254",
            "atsRecordKey": "rapidmodscreener_ats06254",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06255": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06255",
            "atsRecordKey": "rapidmodscreener_ats06255",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06265": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "MVA should fire once user selects and answer"
        },
        "link": {
            "name": "What is the best way to con\ufb01rm a diagnosis of unipolar depression for Mark quiz",
            "displayTitle": "What is the best way to con\ufb01rm a diagnosis of unipolar depression for Mark quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|what is the best way to con\ufb01rm a diagnosis of unipolar depression for mark quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "What is the best way to con\ufb01rm a diagnosis of unipolar depression for Mark",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06265",
            "atsRecordKey": "rapidmodscreener_ats06265",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06246": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06246",
            "atsRecordKey": "rapidmodscreener_ats06246",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06249": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06249",
            "atsRecordKey": "rapidmodscreener_ats06249",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06251": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06251",
            "atsRecordKey": "rapidmodscreener_ats06251",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06256": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "MVA should fire once user selects and answer"
        },
        "link": {
            "name": "Based on Mark\u2019s history with medication quiz",
            "displayTitle": "Based on Mark\u2019s history with medication quiz",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|based on mark\u2019s history with medication quiz"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Based on Mark\u2019s history with medication",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06256",
            "atsRecordKey": "rapidmodscreener_ats06256",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06257": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show question",
            "displayTitle": "show question",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show question"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show question",
            "fieldValue": "show question",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06257",
            "atsRecordKey": "rapidmodscreener_ats06257",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06258": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "next",
            "displayTitle": "next",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|next"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "next",
            "fieldValue": "next",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06258",
            "atsRecordKey": "rapidmodscreener_ats06258",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06259": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "screening patients with bipolar disorder in practice",
            "prefix": "rapidmodscreener",
            "url": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "path": "/patient-case-quizzes/screening-patients-with-bipolar-disorder-in-practice",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|screening patients with bipolar disorder in practice"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Support",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "show explanation",
            "displayTitle": "show explanation",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|screening patients with bipolar disorder in practice||form|show explanation"
        },
        "component": {
            "name": "quick poll",
            "title": "Screening Patients With Bipolar Disorder in Practice",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Patient Case Navigator",
            "category": "Quiz",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "show explanation",
            "fieldValue": "show explanation",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06259",
            "atsRecordKey": "rapidmodscreener_ats06259",
            "created": "11/20/24 4:27 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06267": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "multimedia",
            "prefix": "rapidmodscreener",
            "url": "/multimedia",
            "path": "/multimedia",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|multimedia"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "watch now"
        },
        "link": {
            "name": "watch now",
            "displayTitle": "watch now",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|multimedia||content|watch now"
        },
        "component": {
            "name": "cta",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06267",
            "atsRecordKey": "rapidmodscreener_ats06267",
            "created": "11/20/24 4:30 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06275": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": "click on multimedia on breadcrumb"
        },
        "link": {
            "name": "multimedia",
            "displayTitle": "multimedia",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|multimedia"
        },
        "component": {
            "name": "cta",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06275",
            "atsRecordKey": "rapidmodscreener_ats06275",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06269": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Introducing the Rapid Mood Screener Tool video is clicked to Start"
        },
        "link": {
            "name": "Introducing the Rapid Mood Screener Tool - start",
            "displayTitle": "Introducing the Rapid Mood Screener Tool - start",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|introducing the rapid mood screener tool - start"
        },
        "component": {
            "name": "video player",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Introducing the Rapid Mood Screener Tool - start"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Video",
            "category": "Mechanism of Action",
            "name": "multimedia video page play",
            "typeAndCategory": "Video|Mechanism of Action",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Multimedia Video Page Play",
            "nameTitle": "multimedia video page play",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06269",
            "atsRecordKey": "rapidmodscreener_ats06269",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 8:55 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06270": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to replay Introducing the Rapid Mood Screener Tool video"
        },
        "link": {
            "name": "Introducing the Rapid Mood Screener Tool - replay",
            "displayTitle": "Introducing the Rapid Mood Screener Tool - replay",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|introducing the rapid mood screener tool - replay"
        },
        "component": {
            "name": "video player",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Introducing the Rapid Mood Screener Tool - replay"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06270",
            "atsRecordKey": "rapidmodscreener_ats06270",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06271": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Introducing the Rapid Mood Screener Tool video is 25% completed"
        },
        "link": {
            "name": "Introducing the Rapid Mood Screener Tool video - 25",
            "displayTitle": "Introducing the Rapid Mood Screener Tool video - 25",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|introducing the rapid mood screener tool video - 25"
        },
        "component": {
            "name": "video player",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Introducing the Rapid Mood Screener Tool video - 25"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06271",
            "atsRecordKey": "rapidmodscreener_ats06271",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06272": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Introducing the Rapid Mood Screener Tool video is 50% completed"
        },
        "link": {
            "name": "Introducing the Rapid Mood Screener Tool - 50",
            "displayTitle": "Introducing the Rapid Mood Screener Tool - 50",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|introducing the rapid mood screener tool - 50"
        },
        "component": {
            "name": "video player",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Introducing the Rapid Mood Screener Tool - 50"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06272",
            "atsRecordKey": "rapidmodscreener_ats06272",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06273": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Introducing the Rapid Mood Screener Tool video is 75% completed"
        },
        "link": {
            "name": "Introducing the Rapid Mood Screener Tool - 75",
            "displayTitle": "Introducing the Rapid Mood Screener Tool - 75",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|introducing the rapid mood screener tool - 75"
        },
        "component": {
            "name": "video player",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Introducing the Rapid Mood Screener Tool - 75"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06273",
            "atsRecordKey": "rapidmodscreener_ats06273",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06274": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "When Introducing the Rapid Mood Screener Tool video is Ended (100%)"
        },
        "link": {
            "name": "Introducing the Rapid Mood Screener Tool - End",
            "displayTitle": "Introducing the Rapid Mood Screener Tool - End",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|introducing the rapid mood screener tool - end"
        },
        "component": {
            "name": "video player",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Introducing the Rapid Mood Screener Tool - End"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06274",
            "atsRecordKey": "rapidmodscreener_ats06274",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06276": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to pause Introducing the Rapid Mood Screener Tool video"
        },
        "link": {
            "name": "Introducing the Rapid Mood Screener Tool video - pause",
            "displayTitle": "Introducing the Rapid Mood Screener Tool video - pause",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|introducing the rapid mood screener tool video - pause"
        },
        "component": {
            "name": "video player",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Introducing the Rapid Mood Screener Tool video - pause"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06276",
            "atsRecordKey": "rapidmodscreener_ats06276",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06277": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "introducing the rapid mood screener",
            "prefix": "rapidmodscreener",
            "url": "/multimedia/introducing-the-rapid-mood-screener",
            "path": "/multimedia/introducing-the-rapid-mood-screener",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|introducing the rapid mood screener"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Video play",
            "event": "Click to resume Introducing the Rapid Mood Screener Tool video"
        },
        "link": {
            "name": "Introducing the Rapid Mood Screener Tool video - resume",
            "displayTitle": "Introducing the Rapid Mood Screener Tool video - resume",
            "type": "Video-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|introducing the rapid mood screener||content|introducing the rapid mood screener tool video - resume"
        },
        "component": {
            "name": "video player",
            "title": "Introducing the Rapid Mood Screener Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": "Introducing the Rapid Mood Screener Tool video - resume"
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Video play",
            "variablesToFire": "Video play:\nevent14(video start)\nevent65 (content interaction)\nevar25 (site name)\nevar31 (video segment)\nevar38 (custom link/download page)\nevar45 (video name)\nevar49 (pageURL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar98 (interaction name)\nevar99 (interaction type)\nevar101 (content name)\nevar122 (video ID)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06277",
            "atsRecordKey": "rapidmodscreener_ats06277",
            "created": "11/20/24 4:36 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06280": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "bipolar disorder",
            "prefix": "rapidmodscreener",
            "url": "/bipolar-disorder",
            "path": "/bipolar-disorder",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|bipolar disorder"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Symptoms and Prevalence",
            "displayTitle": "Symptoms and Prevalence",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|bipolar disorder||content|symptoms and prevalence"
        },
        "component": {
            "name": "accordion",
            "title": "Bipolar I Disorder Disease State Education",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06280",
            "atsRecordKey": "rapidmodscreener_ats06280",
            "created": "11/20/24 4:47 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06279": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "bipolar disorder",
            "prefix": "rapidmodscreener",
            "url": "/bipolar-disorder",
            "path": "/bipolar-disorder",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|bipolar disorder"
        },
        "category": {
            "primaryCategory": "awareness",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "product-awareness",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Potential Consequences of Missed Bipolar I Diagnosis",
            "displayTitle": "Potential Consequences of Missed Bipolar I Diagnosis",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|bipolar disorder||content|potential consequences of missed bipolar i diagnosis"
        },
        "component": {
            "name": "accordion",
            "title": "Bipolar I Disorder Disease State Education",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06279",
            "atsRecordKey": "rapidmodscreener_ats06279",
            "created": "11/20/24 4:47 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06281": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "quick guides for providers",
            "prefix": "rapidmodscreener",
            "url": "/quick-guides-for-providers",
            "path": "/quick-guides-for-providers",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|quick guides for providers"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "File download",
            "event": ""
        },
        "link": {
            "name": "download now",
            "displayTitle": "download now",
            "type": "Download",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|quick guides for providers||content|download now"
        },
        "component": {
            "name": "cta",
            "title": "Quick Guides for Providers",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Download",
            "category": "Assessment Guide",
            "name": "quick guide page download",
            "typeAndCategory": "Download|Assessment Guide",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "Quick Guide Page Download",
            "nameTitle": "quick guide page download",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Download Link Click",
            "variablesToFire": "event11 (custom file download)\nevent32 (link click)\nevent65 (content interaction)\nevar25 (site name)\nevar29 (link name)\nevar36 (custom file download)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar99 (interaction type)\nevar101 (content name)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06281",
            "atsRecordKey": "rapidmodscreener_ats06281",
            "created": "11/20/24 4:49 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 9:00 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06282": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinical article summaries",
            "prefix": "rapidmodscreener",
            "url": "/clinical-article-summaries",
            "path": "/clinical-article-summaries",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinical article summaries"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "read more",
            "displayTitle": "read more",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|clinical article summaries||content|read more"
        },
        "component": {
            "name": "cta",
            "title": "Clinical Article Summaries",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06282",
            "atsRecordKey": "rapidmodscreener_ats06282",
            "created": "11/20/24 4:51 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06283": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinical article summaries",
            "prefix": "rapidmodscreener",
            "url": "/clinical-article-summaries",
            "path": "/clinical-article-summaries",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinical article summaries"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "characteristics of patients with earlier bipolar disorder",
            "displayTitle": "characteristics of patients with earlier bipolar disorder",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|clinical article summaries||content|characteristics of patients with earlier bipolar disorder"
        },
        "component": {
            "name": "cta",
            "title": "Clinical Article Summaries",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06283",
            "atsRecordKey": "rapidmodscreener_ats06283",
            "created": "11/20/24 5:33 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06285": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "characteristics of patients with early bipolar disorder",
            "prefix": "rapidmodscreener",
            "url": "/clinical-article-summaries/characteristics-of-patients-with-early-bipolar-disorder",
            "path": "/clinical-article-summaries/characteristics-of-patients-with-early-bipolar-disorder",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|characteristics of patients with early bipolar disorder"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Clinical Article Summaries",
            "displayTitle": "Clinical Article Summaries",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|characteristics of patients with early bipolar disorder||content|clinical article summaries"
        },
        "component": {
            "name": "cta",
            "title": "Clinical Article Summaries",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06285",
            "atsRecordKey": "rapidmodscreener_ats06285",
            "created": "11/20/24 5:35 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06318": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "unrecognized bipolar disorder in patients with depression managed in primary care a systematic review and meta analysis",
            "prefix": "rapidmodscreener",
            "url": "/clinical-article-summaries/unrecognized-bipolar-disorder-in-patients-with-depression-managed-in-primary-care-a-systematic-review-and-meta-analysis",
            "path": "/clinical-article-summaries/unrecognized-bipolar-disorder-in-patients-with-depression-managed-in-primary-care-a-systematic-review-and-meta-analysis",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|unrecognized bipolar disorder in patients with depression managed in primary care a systematic review and meta analysis"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "",
            "event": ""
        },
        "link": {
            "name": "Clinical Article Summaries",
            "displayTitle": "Clinical Article Summaries",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|unrecognized bipolar disorder in patients with depression managed in primary care a systematic review and meta analysis||content|clinical article summaries"
        },
        "component": {
            "name": "cta",
            "title": "Clinical Article Summaries",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "",
            "variablesToFire": "#NO MATCH",
            "howToCapture": "#NO MATCH",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06318",
            "atsRecordKey": "rapidmodscreener_ats06318",
            "created": "11/20/24 7:23 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06286": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "https://www.abbvie.com/termsofuse.html",
            "displayTitle": "https://www.abbvie.com/termsofuse.html",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||content|https://www.abbvie.com/termsofuse.html"
        },
        "component": {
            "name": "cta",
            "title": "Terms of Use",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06286",
            "atsRecordKey": "rapidmodscreener_ats06286",
            "created": "11/20/24 5:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06287": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||content|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06287",
            "atsRecordKey": "rapidmodscreener_ats06287",
            "created": "11/20/24 5:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06288": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||content|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06288",
            "atsRecordKey": "rapidmodscreener_ats06288",
            "created": "11/20/24 5:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06289": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "https://www.abbvie.com/privacy.html",
            "displayTitle": "https://www.abbvie.com/privacy.html",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||content|https://www.abbvie.com/privacy.html"
        },
        "component": {
            "name": "cta",
            "title": "Access the Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06289",
            "atsRecordKey": "rapidmodscreener_ats06289",
            "created": "11/20/24 5:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06321": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||content|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06321",
            "atsRecordKey": "rapidmodscreener_ats06321",
            "created": "11/22/24 4:06 AM",
            "createdBy": "anitha.kollipara@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06322": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||content|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06322",
            "atsRecordKey": "rapidmodscreener_ats06322",
            "created": "11/22/24 4:06 AM",
            "createdBy": "anitha.kollipara@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06290": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "MVA should fire once user clicks \"Accept"
        },
        "link": {
            "name": "agree",
            "displayTitle": "agree",
            "type": "form-initiation",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|agree"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "init",
            "fieldName": "Terms of Use",
            "fieldValue": "agree",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "Terms of Use",
            "type": "Quick Poll"
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Form",
            "category": "Symptom Quiz",
            "name": "rms tool accept submission",
            "typeAndCategory": "Form|Symptom Quiz",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "RMS Tool Accept Submission",
            "nameTitle": "rms tool accept submission",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06290",
            "atsRecordKey": "rapidmodscreener_ats06290",
            "created": "11/20/24 5:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 8:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06291": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "disagree",
            "displayTitle": "disagree",
            "type": "form-initiation",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|disagree"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "init",
            "fieldName": "Terms of Use",
            "fieldValue": "disagree",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "Terms of Use",
            "type": "Quick Poll"
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06291",
            "atsRecordKey": "rapidmodscreener_ats06291",
            "created": "11/20/24 5:42 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06323": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "MVA should fire once user clicks \"Submit"
        },
        "link": {
            "name": "submit",
            "displayTitle": "submit",
            "type": "form-submission",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|submit"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "Submit - Step 1",
            "fieldName": "submit",
            "fieldValue": "submit",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06323",
            "atsRecordKey": "rapidmodscreener_ats06323",
            "created": "11/22/24 4:06 AM",
            "createdBy": "anitha.kollipara@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06292": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "select yes/no"
        },
        "link": {
            "name": "Have there been at least 6 different periods of time (at least 2weeks) when you felt deeply depressed?",
            "displayTitle": "Have there been at least 6 different periods of time (at least 2weeks) when you felt deeply depressed?",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|have there been at least 6 different periods of time (at least 2weeks) when you felt deeply depressed?"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Have there been at least 6 different periods of time (at least 2weeks) when you felt deeply depressed?",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06292",
            "atsRecordKey": "rapidmodscreener_ats06292",
            "created": "11/20/24 6:13 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06293": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "select yes/no"
        },
        "link": {
            "name": "Did you have problems with depression before the age of 18?",
            "displayTitle": "Did you have problems with depression before the age of 18?",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|did you have problems with depression before the age of 18?"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Did you have problems with depression before the age of 18?",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06293",
            "atsRecordKey": "rapidmodscreener_ats06293",
            "created": "11/20/24 6:13 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06297": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "select yes/no"
        },
        "link": {
            "name": "Have you ever had to stop or change your antidepressant because it made you highly irritable or hyper?",
            "displayTitle": "Have you ever had to stop or change your antidepressant because it made you highly irritable or hyper?",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|have you ever had to stop or change your antidepressant because it made you highly irritable or hyper?"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Have you ever had to stop or change your antidepressant because it made you highly irritable or hyper?",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06297",
            "atsRecordKey": "rapidmodscreener_ats06297",
            "created": "11/20/24 6:13 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06296": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "select yes/no"
        },
        "link": {
            "name": "Have you ever had a period of at least 1 week during which you were more talkative",
            "displayTitle": "Have you ever had a period of at least 1 week during which you were more talkative",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|have you ever had a period of at least 1 week during which you were more talkative"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Have you ever had a period of at least 1 week during which you were more talkative",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06296",
            "atsRecordKey": "rapidmodscreener_ats06296",
            "created": "11/20/24 6:13 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06295": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "select yes/no"
        },
        "link": {
            "name": "Have you ever had a period of at least 1 week during which you felt any of the following",
            "displayTitle": "Have you ever had a period of at least 1 week during which you felt any of the following",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|have you ever had a period of at least 1 week during which you felt any of the following"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Have you ever had a period of at least 1 week during which you felt any of the following",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06295",
            "atsRecordKey": "rapidmodscreener_ats06295",
            "created": "11/20/24 6:13 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06294": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "select yes/no"
        },
        "link": {
            "name": "Have you ever had a period of at least 1 week during which you needed much less sleep than usual",
            "displayTitle": "Have you ever had a period of at least 1 week during which you needed much less sleep than usual",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|have you ever had a period of at least 1 week during which you needed much less sleep than usual"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "Have you ever had a period of at least 1 week during which you needed much less sleep than usual",
            "fieldValue": "dynamic",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06294",
            "atsRecordKey": "rapidmodscreener_ats06294",
            "created": "11/20/24 6:13 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:06 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06298": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": "MVA should fire once user clicks \"Submit"
        },
        "link": {
            "name": "submit",
            "displayTitle": "submit",
            "type": "form-submission",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|submit"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "submit",
            "fieldName": "submit",
            "fieldValue": "submit",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "Primary",
            "type": "Form",
            "category": "Symptom Quiz",
            "name": "rms tool quiz submission button",
            "typeAndCategory": "Form|Symptom Quiz",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "RMS Tool Quiz Submission Button",
            "nameTitle": "rms tool quiz submission button",
            "primary": "TRUE",
            "secondary": "",
            "eventsToFire": "e22, e24, c3/v3, v120",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06298",
            "atsRecordKey": "rapidmodscreener_ats06298",
            "created": "11/20/24 6:17 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 8:53 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06299": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "print RMS questionnaire",
            "displayTitle": "print RMS questionnaire",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||content|print rms questionnaire"
        },
        "component": {
            "name": "cta",
            "title": "Access the Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06299",
            "atsRecordKey": "rapidmodscreener_ats06299",
            "created": "11/20/24 6:17 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06300": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "File download",
            "event": ""
        },
        "link": {
            "name": "download clinician guide",
            "displayTitle": "download clinician guide",
            "type": "Download",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||content|download clinician guide"
        },
        "component": {
            "name": "cta",
            "title": "Access the Tool",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Download Link Click",
            "variablesToFire": "event11 (custom file download)\nevent32 (link click)\nevent65 (content interaction)\nevar25 (site name)\nevar29 (link name)\nevar36 (custom file download)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar99 (interaction type)\nevar101 (content name)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06300",
            "atsRecordKey": "rapidmodscreener_ats06300",
            "created": "11/20/24 6:17 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06301": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "rms",
            "prefix": "rapidmodscreener",
            "url": "/rms",
            "path": "/rms",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|rms"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "Other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Form interaction",
            "event": ""
        },
        "link": {
            "name": "reset",
            "displayTitle": "reset",
            "type": "form-interaction",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|rms||form|reset"
        },
        "component": {
            "name": "form",
            "title": "Access the Tool",
            "position": "content",
            "type": "form",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "Access the Tool",
            "category": "Interaction",
            "subCategory": "Polls",
            "stepName": "",
            "fieldName": "reset",
            "fieldValue": "reset",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Form interaction",
            "variablesToFire": "event32 (link click)\nevent61 (form interaction)\nevar8 (form field name)\nevar9 (form field value)\nevar25 (site name)\nevar29 (link name)\nevar33 (form name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06301",
            "atsRecordKey": "rapidmodscreener_ats06301",
            "created": "11/20/24 6:17 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06302": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "page not found",
            "prefix": "rapidmodscreener",
            "url": "/page-not-found",
            "path": "/page-not-found",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|page not found"
        },
        "category": {
            "primaryCategory": "error page",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "Error Page",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Other",
            "patient": "",
            "messageBucket": "other"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "go to homepage",
            "displayTitle": "go to homepage",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|page not found||content|go to homepage"
        },
        "component": {
            "name": "cta",
            "title": "Page Not Found",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06302",
            "atsRecordKey": "rapidmodscreener_ats06302",
            "created": "11/20/24 6:19 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06313": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinician guide",
            "prefix": "rapidmodscreener",
            "url": "/clinician-guide",
            "path": "/clinician-guide",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinician guide"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "journal website",
            "displayTitle": "journal website",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|clinician guide||content|journal website"
        },
        "component": {
            "name": "cta",
            "title": "Clinician Guide",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06313",
            "atsRecordKey": "rapidmodscreener_ats06313",
            "created": "11/20/24 6:26 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06309": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinician guide",
            "prefix": "rapidmodscreener",
            "url": "/clinician-guide",
            "path": "/clinician-guide",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinician guide"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|clinician guide||content|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06309",
            "atsRecordKey": "rapidmodscreener_ats06309",
            "created": "11/20/24 6:26 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06310": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinician guide",
            "prefix": "rapidmodscreener",
            "url": "/clinician-guide",
            "path": "/clinician-guide",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinician guide"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|clinician guide||content|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06310",
            "atsRecordKey": "rapidmodscreener_ats06310",
            "created": "11/20/24 6:26 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06311": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinician guide",
            "prefix": "rapidmodscreener",
            "url": "/clinician-guide",
            "path": "/clinician-guide",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinician guide"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Clinical Utility",
            "displayTitle": "Clinical Utility",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|clinician guide||content|clinical utility"
        },
        "component": {
            "name": "accordion",
            "title": "Clinician Guide",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06311",
            "atsRecordKey": "rapidmodscreener_ats06311",
            "created": "11/20/24 6:26 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06308": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinician guide",
            "prefix": "rapidmodscreener",
            "url": "/clinician-guide",
            "path": "/clinician-guide",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinician guide"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "Scoring and Validity",
            "displayTitle": "Scoring and Validity",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|clinician guide||content|scoring and validity"
        },
        "component": {
            "name": "accordion",
            "title": "Clinician Guide",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06308",
            "atsRecordKey": "rapidmodscreener_ats06308",
            "created": "11/20/24 6:26 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06312": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "clinician guide",
            "prefix": "rapidmodscreener",
            "url": "/clinician-guide",
            "path": "/clinician-guide",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|clinician guide"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "File download",
            "event": ""
        },
        "link": {
            "name": "download pdf",
            "displayTitle": "download pdf",
            "type": "Download",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|clinician guide||content|download pdf"
        },
        "component": {
            "name": "cta",
            "title": "Clinician Guide",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Download Link Click",
            "variablesToFire": "event11 (custom file download)\nevent32 (link click)\nevent65 (content interaction)\nevar25 (site name)\nevar29 (link name)\nevar36 (custom file download)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)\nevar99 (interaction type)\nevar101 (content name)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06312",
            "atsRecordKey": "rapidmodscreener_ats06312",
            "created": "11/20/24 6:26 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06314": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "development",
            "prefix": "rapidmodscreener",
            "url": "/development",
            "path": "/development",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|development"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "journal website",
            "displayTitle": "journal website",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|development||content|journal website"
        },
        "component": {
            "name": "cta",
            "title": "Development and Validation",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06314",
            "atsRecordKey": "rapidmodscreener_ats06314",
            "created": "11/20/24 6:28 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06315": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "development",
            "prefix": "rapidmodscreener",
            "url": "/development",
            "path": "/development",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|development"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "back",
            "displayTitle": "back",
            "type": "Internal",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|development||content|back"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "Internal Link Click",
            "variablesToFire": "event32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06315",
            "atsRecordKey": "rapidmodscreener_ats06315",
            "created": "11/20/24 6:28 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    },
    "ats06316": {
        "projectDetails": {
            "projectTrackDetails": "ABBV-US-01776-MC - Rapidmoodscreener.com : Rapid Mood Screener Updates - https://rms-dev.hmpcloud.com/",
            "status": "Initial Approval  - Passed - Approved for Authoring",
            "defects": "",
            "additionalFunction": "",
            "recordType": "Interaction",
            "thirdPartyTaggingReq": ""
        },
        "pageInfo": {
            "pageName": "development",
            "prefix": "rapidmodscreener",
            "url": "/development",
            "path": "/development",
            "pageTaxonomyName": "rapidmodscreener|ub|neuro|bd|development"
        },
        "category": {
            "primaryCategory": "resources",
            "subCategory1": "",
            "subCategory2": ""
        },
        "attributes": {
            "country": "us",
            "language": "en-us",
            "type": "resources",
            "globalISI": "",
            "promoMatsNumber": "",
            "globalISIPromoMatDocID": "|"
        },
        "journey": {
            "content": "Educate",
            "patient": "",
            "messageBucket": "Awareness"
        },
        "product": {
            "franchise": "Neurology (Neuro)",
            "franchiseCalc": "neuro",
            "franchiseStartingPosition": 11,
            "indication": "Bipolar I disorder (BD)",
            "indicationCalc": "bd",
            "indicationStartingPosition": 20,
            "division": "USMA",
            "brand": "DSA",
            "name": ""
        },
        "site": {
            "type": "unbranded",
            "audience": "Health Care Professional (HCP)",
            "experience": "dsa",
            "typeBrandingAudience": "dsa|unbranded|hcp"
        },
        "interaction": {
            "id": "",
            "type": "Link click",
            "event": ""
        },
        "link": {
            "name": "confirm",
            "displayTitle": "confirm",
            "type": "External",
            "url": "",
            "internalLinkTracking": "rapidmodscreener|ub|bd|neuro|development||content|confirm"
        },
        "component": {
            "name": "cta",
            "title": "You are now leaving Rapid Mood Screener",
            "position": "content",
            "type": "content",
            "l10Title": ""
        },
        "content": {
            "type": ""
        },
        "form": {
            "name": "",
            "category": "",
            "subCategory": "",
            "stepName": "",
            "fieldName": "",
            "fieldValue": "",
            "piiFieldName": ""
        },
        "video": {
            "name": ""
        },
        "tool": {
            "name": "",
            "type": ""
        },
        "menu": {
            "location": "",
            "level": ""
        },
        "mva": {
            "tier": "",
            "type": "",
            "category": "",
            "name": "",
            "typeAndCategory": "",
            "timelineValue": "",
            "calcFriendlyPreferredValue": "",
            "nameTitle": "",
            "primary": "",
            "secondary": "",
            "eventsToFire": "",
            "tridCookieValue": ""
        },
        "tracking": {
            "tagType": "External Link Click",
            "variablesToFire": "event12 (custom exit link)\nevent32 (link click)\nevar25 (site name)\nevar29 (link name)\nevar37 (custom exit link)\nevar38 (custom link/download page)\nevar49 (page URL)\nevar58 (content type)\nevar66 (component type|component title)\nevar95 (content interaction location)",
            "howToCapture": "On click",
            "contentViewReqImageText": "",
            "contentViewReqCustomCssClass": "",
            "colorSchema": ""
        },
        "pixels": {
            "audienceStudio": "",
            "crossix": "",
            "facebook": "",
            "floodlight": "",
            "instagram": "",
            "iqvia": "",
            "linkedin": "",
            "liverampEcst": "",
            "snapchat": "",
            "tiktok": "",
            "twitter": ""
        },
        "metadata": {
            "recordKey": "ats06316",
            "atsRecordKey": "rapidmodscreener_ats06316",
            "created": "11/20/24 6:28 AM",
            "createdBy": "lganesan@abbvie.com",
            "modified": "11/22/24 4:14 AM",
            "modifiedBy": "anitha.kollipara@abbvie.com"
        }
    }
}