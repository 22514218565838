import { useState, useEffect } from "react";

import { trackDigitalData } from "../../../helpers/analytics";

import NewBanner from "../../../components/NewBanner/NewBanner";
import BrightcoveVideo from "../../../components/BrightCove/BrightCove";
import classes from "./IntroducingTheRapidMoodScreener.module.css";
import References from "../../../components/References/References";

const IntroducingTheRapidMoodScreener = () => {
    useEffect(() => {
        trackDigitalData('pageLoad', 'ats06268');
    }, []);
    return (
        <>
            <NewBanner title="Introducing the Rapid Mood Screener Tool" breadcrumbs={[{ title: "Clinical Experience", link: null }, { title: "Multimedia", link: '/multimedia', trackId: 'ats06275' }, { title: "Introducing the Rapid Mood Screener Tool", link: null }]} />
            <BrightcoveVideo videoId="6364557291112" keys={{
              start: 'ats06269',
              replay: 'ats06270',
              resume: 'ats06277',
              pause: 'ats06276',
              '25%': 'ats06271',
              '50%': 'ats06272',
              '75%': 'ats06273',
              'End': 'ats06274'
            }} />
            <p className={classes.articleCaption}>The Rapid Mood Screener (RMS) is a brief, self-administered screening tool for bipolar I disorder. In this video, Dr Roger McIntyre reviews the utility of the RMS in a busy clinical setting.</p>
            <p className={classes.articleCaption}>KOL: Roger McIntyre, MD, FRCPC</p>
            <p className={classes.smallText}>ABBV-US-00773-MC, V2.0 | Approved 01/2024 | AbbVie Medical Affairs</p>
        </>
    );
};

export default IntroducingTheRapidMoodScreener;
