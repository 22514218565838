// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Quiz_quizIframe__F3tgj {\n    width: 100%;\n    height: 800px;\n    margin-top: 24px;\n    overflow: scroll;\n}", "",{"version":3,"sources":["webpack://src/components/Quiz/Quiz.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".quizIframe {\n    width: 100%;\n    height: 800px;\n    margin-top: 24px;\n    overflow: scroll;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quizIframe": "Quiz_quizIframe__F3tgj"
};
export default ___CSS_LOADER_EXPORT___;
