import React, { useEffect, useState } from "react";

import classes from "./Quiz.module.css";
import { trackDigitalData } from "../../helpers/analytics";

const Quiz = (props) => {
    const [currentPhase, setCurrentPhase] = useState('');
    const [questionNumber, setQuestionNumber] = useState(0);

    useEffect(() => {
        const handleMessages = (event) => {
            // Ensure the message is from the current quiz's iframe
            if (event.source !== document.getElementById(props.id).contentWindow) {
                return;
            }
            trackQuizActions(props.keys, event, currentPhase, setCurrentPhase, questionNumber, setQuestionNumber);
        };

        window.addEventListener('message', handleMessages, true);

        // Cleanup listener on component unmount
        return () => {
            window.removeEventListener('message', handleMessages, true);
        };
    }, [props.keys, props.id, currentPhase, questionNumber]); // Include currentPhase and questionNumber in the dependency array

    return (
        <>
            <div className={classes.quizContainer}>
                <iframe
                    id={props.id}
                    className={classes.quizIframe}
                    src={props.embedCode}
                    title="Quiz"
                />
            </div>
        </>
    );
};

const trackQuizActions = (keys, event, currentPhase, setCurrentPhase, questionNumber, setQuestionNumber) => {
    // Safety check for keys array
    if (!Array.isArray(keys) || !keys.length) {
        return;
    }

    if (event.data.action === "Block_Submit") {
        setCurrentPhase('answer');
        if (keys[questionNumber]?.answer) {
            let i = 0;
            for (const answer of keys[questionNumber].answer) {
                console.log('tracking answer');
                console.log(event.data.answer);
                if(i === 0){
                    trackDigitalData('interaction', answer, false, {answer: event.data.answer});
                } else{
                    trackDigitalData('interaction', answer);
                }
                i++;
            }
        }
    } else if (event.data.action === "Block_View") {
        setCurrentPhase('next');
        setQuestionNumber(prev => prev + 1);
        if (keys[questionNumber]?.next) {
            console.log('tracking next');
            trackDigitalData('interaction', keys[questionNumber].next);
        }
    } else if (event.data.action === "Block_View_AnswerExplanation" && currentPhase === 'show question') {
        setCurrentPhase('show explanation');
        if (keys[questionNumber]?.explanation) {
            console.log('tracking explanation');
            trackDigitalData('interaction', keys[questionNumber].explanation);
        }
    } else if (event.data.action === undefined && (currentPhase === 'answer')) {
        setCurrentPhase('show question');
        if (keys[questionNumber]?.question) {
            console.log('tracking question');
            trackDigitalData('interaction', keys[questionNumber].question);
        }
    }
};

export default Quiz;